import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ListItem from "../components/ListItem";

// markup
const ProductPageTemplate = ({ data }) => {
  const pageContent = data.pageContent.nodes[0];
  const listItems = data.listItems.nodes;

  return (
    <Layout page={pageContent}>
      <Seo
        title={pageContent.frontmatter.title}
        description={pageContent.frontmatter.description}
      />
      <div className="home-container">
        <div
          className="quote-box"
          dangerouslySetInnerHTML={{ __html: pageContent.html }}
        />
      </div>

      <div className="container">
        {listItems.map((listItem) => (
          <ListItem item={listItem} key={listItem.frontmatter.title} />
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query productPageQuery($slug: String!, $title: String!) {
    pageContent: allMarkdownRemark(
      filter: { fields: { slug: { eq: $slug } } }
    ) {
      nodes {
        frontmatter {
          title
          description
          header_image {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        html
      }
    }
    listItems: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: $title } } }
      sort: { fields: frontmatter___datetime, order: ASC }
    ) {
      nodes {
        frontmatter {
          title
          url
          headline
          buttontext
          datetime
          image {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        html
      }
    }
  }
`;

export default ProductPageTemplate;
